import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { getAuth } from "firebase/auth";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Connect } from "./components/Connect";
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { InvoiceConnect } from "./components/InvoiceConnect";
import { firebaseApp } from "./firebase";

const App: React.FC = () => {
  const themeLight = createTheme({
    palette: {
      background: {
        default: "#e4f0e2",
      },
    },
  });

  // const themeDark = createTheme({
  //   palette: {
  //     background: {
  //       default: "#222222",
  //     },
  //     text: {
  //       primary: "#ffffff",
  //     },
  //   },
  // });
  //

  const auth = getAuth(firebaseApp);

  const user = auth.currentUser;

  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />

      {/* {user ? ( */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/invoice" element={<InvoiceConnect />} />
          </Routes>
        </BrowserRouter>
      {/* ) : ( */}
      {/*   <Login /> */}
      {/* )} */}
    </ThemeProvider>
  );
};

export default App;
