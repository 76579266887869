import React from "react";
import { Button } from "@mui/material";

export const InvoiceConnect: React.FC = () => {
  return (
    <div>
      <form
        action="https://wwwtest.einvoice.nat.gov.tw/APCONSUMER/BTC103I/"
        // action="https://wwwtest.einvoice.nat.gov.tw/APMEMBERVAN/membercardlogin"
        method="POST"
      >
        <input name="card_ban" value="52759308" />
        <input name="card_no1" value="bmljaG9sYWloc3VAZ21haWwuY29t" />
        <input name="card_no2" value="bmljaG9sYWloc3VAZ21haWwuY29t" />
        <input name="card_type" value="RUoxNTI3" />
        <input name="back_url" value="https://testeinvoice.qpos.me/connect" />
        <input name="token" value="d12345678" />
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};
