import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC7lrtUS5lzA54OPICemxpGwqrNzaQDsn0",
  authDomain: "qpos.me",
  databaseURL: "https://queue-api.firebaseio.com",
  projectId: "queue-api",
  storageBucket: "queue-api.appspot.com",
  messagingSenderId: "1063204016925",
  appId: "1:1063204016925:web:ac56e1449881b0728487f4",
  measurementId: "G-D0BVXPXJGW",
};

export const firebaseApp = initializeApp(firebaseConfig);
